import { IServiceTaskService } from '@4r/mf-contracts-4services';
import { IAuthService } from '../../authentication/AuthService';
import { Settings } from '../../contexts/types';
import ApiConfig from '../../api/ApiConfig';
import {
	InventoryClient,
	LockboxClient,
	OrderClient,
	PropertyClient,
	ReceiveInventoryRequest,
	IssueInventoryRequest,
	SetCoordinatesRequest,
	OrderInventory,
	TransactionInventoryModel,
	EnumModel,
} from '../../api/api';

export interface UserInventory {
	inventoryItems: InventoryItem[];
	locations: EnumModel[];
}

export interface InventoryItem {
	productId: string;
	quantity: number;
	productName: string;
	locationId: string;
}

export default class ApiServiceTaskService implements IServiceTaskService {
	private inventoryClient: InventoryClient;


	private propertyClient: PropertyClient;

	private orderClient: OrderClient;

	constructor(private readonly authService: IAuthService, settings: Settings) {
		this.inventoryClient = new InventoryClient(new ApiConfig(this.authService), settings.API_URL);

		this.propertyClient = new PropertyClient(new ApiConfig(this.authService), settings.API_URL);
		this.orderClient = new OrderClient(new ApiConfig(this.authService), settings.API_URL);
	}

	async findOrderInventory(orderId: string): Promise<OrderInventory> {
		const response = await this.inventoryClient.order(orderId);
		return response;
	}

	async getUserAvailableInventory(): Promise<UserInventory> {
		const response = await this.inventoryClient.userAvailableInventory();
		const locations = response.locations || [];
		if (response.products) {
			const productsDict = Object.assign({}, ...response.products?.map((x) => ({ [x.id]: x.name })));
			const itemsArray: InventoryItem[] = [];
			response.items?.forEach((item) => {
				if (item.productId && item.quantity !== 0) {
					itemsArray.push({
						productId: item.productId,
						productName: productsDict[item.productId],
						quantity: item.quantity,
						locationId: item.locationId,
					});
				}
			});

			return { inventoryItems: itemsArray, locations };
		}

		return { inventoryItems: [], locations };
	}

	async assignInventory(
		orderId: string,
		locationId: string,
		lineItems: TransactionInventoryModel[],
		propertyId: string,
		serviceTaskId: string,
	): Promise<void> {
		const request: IssueInventoryRequest = {
			locationId,
			lineItems,
			orderId,
			propertyId,
			serviceTaskId,
		};
		await this.inventoryClient.issueInventory(request);
	}

	async removeInventory(
		orderId: string,
		locationId: string,
		lineItems: TransactionInventoryModel[],
		propertyId: string,
		serviceTaskId: string,
	): Promise<void> {
		const request: ReceiveInventoryRequest = {
			lineItems,
			locationId,
			orderId,
			propertyId,
			serviceTaskId,
		};
		await this.inventoryClient.receiveInventory(request);
	}

	async evaluateServiceTaskStatus(orderId: string, serviceTaskId: string, actionName: string): Promise<void> {
		await this.orderClient.evaluateServiceTaskStatus(orderId, serviceTaskId, actionName, {});
	}

	async updateServiceTask(orderId: string, serviceTaskId: string, description: string): Promise<void> {
		await this.orderClient.updateServiceTask(orderId, serviceTaskId, { description });
	}
}
