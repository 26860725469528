import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { MFContext, getSharedComponent } from '@4r/mf-host';
import { useConfig } from '@4r/mf-app';
import { useFeatureFlag } from '@4r/module-common-feature-flags-mf-host';
import { UserContext } from '../../contexts/UserContext';
import { AuthContext } from '../../authentication/AuthProvider';
import { FeatureFlags } from '../../common/consts';

import style from './PropertyLifecycleDetailsPage.module.scss';
import './PropertyLifecycleDetailsPage.scss';

const PropertyLifecycleDetailsPage = () => {
	const { LifecycleMicrofrontend } = React.useContext(MFContext);
	const config = useConfig('LifecycleMicrofrontend');
	const { id } = useParams<{ id: string }>();

	// new Lifecycle details
	const newPropertyLifecycleDashboardEnabled = useFeatureFlag(FeatureFlags.PropertyLifecycles);
	if (newPropertyLifecycleDashboardEnabled) {
		if (LifecycleMicrofrontend) {
			const LifecycleDetailsPage = getSharedComponent('LifecycleDetailsPage');

			const user = useContext(UserContext);
			const { getUser } = useContext(AuthContext);
			const currentUser = getUser();
			
			if (!LifecycleDetailsPage) return null;
			return (
				<div className={style['lifecycle-detail-page']}>
					<LifecycleDetailsPage
						config={config[0]}
						lifecycleId={id}
						currentUser={user.current}
						currentUserName={currentUser ? `${currentUser.given_name} ${currentUser.family_name}` : undefined}
					/>
				</div>
			);
		}
	}

	// legacy Lifecycle details
	const LegacyLifecycleDetailsPage = getSharedComponent('LegacyLifecycleDetailsPage');
	if (!LegacyLifecycleDetailsPage) return null;
	return <LegacyLifecycleDetailsPage config={config[0]} lifecycleId={id} />;
};

export default PropertyLifecycleDetailsPage;
