import React, { useContext, useMemo, useState } from 'react';
import { groupBy, useQueryString } from '@4r/module-common-mf-app';
import './OrdersPage.scss';
import { Input, Space, Spin } from 'antd';
import { OrderDetailsResponse } from '../../api/api';
import { TabDesc, FilterTabs } from '../../components/Tabs';
import OrderList from './OrderList';
import OrderMap from './OrderMap';
import OrderVendor from './OrderVendor';
import { OfflineOrderContext } from '../../contexts/OfflineOrderContext';

const tabs: TabDesc<string>[] = [
	{
		name: 'Incomplete',
		id: 'incomplete',
	},
	{
		name: 'Today',
		id: 'today',
	},
	{
		name: 'Tomorrow',
		id: 'tomorrow',
	},
	{
		name: 'Assigned To Vendors',
		id: 'assignedToVendors',
	},
];

const defaultTabId = 'today';

interface IOrderPage {
	dataLoading: boolean;
}

const OrdersPage = ({ dataLoading }: IOrderPage) => {
	const [tabId, setTabId] = useQueryString('tabId', defaultTabId, { allowedValues: tabs.map((x) => x.id) });
	const [selectedId, setSelectedId] = useQueryString('id');
	const [searchText, setSearchText] = useState<string | null>(null);
	const { orders: data } = useContext(OfflineOrderContext);

	const onTabChange = (id: string) => {
		setSelectedId(undefined);
		setTabId(id);
	};

	const tabFilteredData = useMemo(() => {
		let filterFunc: (x: OrderDetailsResponse) => boolean;
		switch (tabId) {
			case 'today':
			default:
				filterFunc = (x) => x.properties.isAssignedForToday;
				break;
			case 'tomorrow':
				filterFunc = (x) => x.properties.isAssignedForTomorrow;
				break;
			case 'incomplete':
				filterFunc = (x) => x.properties.isIncomplete;
				break;
			case 'assignedToVendors':
				filterFunc = (x) => x.order.isVendorInternal === false;
		}

		return data?.filter((x) => filterFunc(x)) ?? [];
	}, [tabId, data]);

	const searchFilteredData = useMemo(() => {
		if (!searchText) return tabFilteredData;
		return tabFilteredData.filter((x) =>
			[x.order.name, x.order.property.propertyNo, ...Object.values(x.order.property.address)]
				.join(' ')
				.toLowerCase()
				.includes(searchText.toLowerCase()),
		);
	}, [tabFilteredData, searchText]);

	const vendorGroups = useMemo(
		() =>
			groupBy(
				searchFilteredData.filter((x) => !!x.order.vendor?.id),
				(x) => x.order.vendor?.id ?? '',
				(x) => x,
			),
		[searchFilteredData],
	);

	return (
		<div className="orders-page page">
			<FilterTabs tabs={tabs} selected={tabId || defaultTabId} onChanged={onTabChange}>
				<Space direction="vertical" size="middle">
					<Input
						placeholder="Search by Order ID, Property ID or Property Address..."
						onChange={(e) => setSearchText(e.target.value)}
						allowClear
						size="large"
						className="property-search-input"
					/>
					<OrderMap
						orders={searchFilteredData
							.filter((x) => x.order.property && x.order.property.geoCoordinates)
							.map((x) => ({
								id: x.order.id,
								latitude: x.order.property.geoCoordinates.latitude,
								longitude: x.order.property.geoCoordinates.longitude,
							}))}
						selectedId={selectedId || null}
						onSelect={(x) => setSelectedId(x || undefined)}
					/>
					{tabId === 'assignedToVendors' ? (
						<>
							{dataLoading && <Spin className="flex justify-center" />}
							{Array.from(vendorGroups, ([key, value]) => (
								<OrderVendor key={key} orders={value} selectedId={selectedId || null} />
							))}
						</>
					) : (
						<OrderList ordersData={searchFilteredData} loading={dataLoading} selectedId={selectedId || null} />
					)}
				</Space>
			</FilterTabs>
		</div>
	);
};

export default OrdersPage;
