import React, { useContext } from 'react';
import { MFContext } from '@4r/mf-host';
import { AccessContext } from '@4r/module-common-authorization';
import { useGlobalState } from '../../state/Store';
import { PageStatus } from '../../state/Actions';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';
import { AuthContext } from '../../authentication/AuthProvider';

const ScopingManagmentPage: React.FC = () => {
	const { ScopingManagementMicrofrontend } = useContext(MFContext);
	const [onlineStatus] = useGlobalState('online');
	const auth = React.useContext(AuthContext);
	const access = useContext(AccessContext);
	const PAGE_TITLE = 'Scoping Management';

	if (onlineStatus === PageStatus.offline) {
		return <OfflineMessage pageTitle={PAGE_TITLE} />;
	}

	if (!ScopingManagementMicrofrontend) {
		return null;
	}

	return <ScopingManagementMicrofrontend isAuthenticated={auth.isAuthenticated} currentUser={access.user} />;
};

export default ScopingManagmentPage;
