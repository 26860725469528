import React from 'react';

import { OrderModel } from '../../api/api';
import { MappedCIs } from './common';
import OrderCard from './OrderCard';

interface OrderListMobileProps {
	orders: OrderModel[];
	mappedCIs: MappedCIs;
	isMobile?: boolean;
}

const OrderListMobile = (props: OrderListMobileProps) => {
	const { orders, mappedCIs, isMobile } = props;

	return (
		<div className="order-list-mobile">
			{orders.map((order) => (
				<OrderCard key={order.id} order={order} cCIs={mappedCIs[order.id]} isMobile={isMobile} />
			))}
		</div>
	);
};

export default OrderListMobile;
