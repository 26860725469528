import React, { useContext } from 'react';
import { MFContext, getSharedComponent } from '@4r/mf-host';
import { withRouter } from 'react-router-dom';
import { useConfig } from '@4r/mf-app';
import { useFeatureFlag } from '@4r/module-common-feature-flags-mf-host';

import { useGlobalState } from '../../state/Store';
import OfflineMessage from '../../components/OfflineMessage/OfflineMessage';
import { PageStatus } from '../../state/Actions';

import { AuthContext } from '../../authentication/AuthProvider';
import { FeatureFlags } from '../../common/consts';

const PropertyLifecyclePage: React.FC = () => {
	const { FormsMicrofrontend } = React.useContext(MFContext);
	const [onlineStatus] = useGlobalState('online');
	if (onlineStatus === PageStatus.offline) {
		return <OfflineMessage pageTitle="Property Lifecycles" />;
	}

	// new Lifecycle dashboard
	const newPropertyLifecycleDashboardEnabled = useFeatureFlag(FeatureFlags.PropertyLifecycles);
	if (newPropertyLifecycleDashboardEnabled) {
		if (FormsMicrofrontend) {
			const StandaloneDashboardView = getSharedComponent('DashboardView');
			if (!StandaloneDashboardView) return null;

			const config = useConfig('FormsMicrofrontend');
			return <StandaloneDashboardView config={config[0]} dashboardName="LifecyclesMaintenanceDashboard" />;
		}
	}

	// legacy Lifecycle dashboard
	const { LifecycleMicrofrontend } = useContext(MFContext);
	const auth = useContext(AuthContext);

	if (!LifecycleMicrofrontend) {
		return null;
	}

	const user = auth.isAuthenticated ? auth.getUser() : null;
	const LifecyclePage = getSharedComponent('LifecyclePage');
	const config = useConfig('LifecycleMicrofrontend');

	if (!LifecyclePage) return null;
	return <LifecyclePage config={config[0]} userFullName={`${user?.given_name} ${user?.family_name}`} mode="Technician" />;
};

export default withRouter(PropertyLifecyclePage);
