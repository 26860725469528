import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Badge, Col, Divider, Menu, Radio, Row, Tag, Modal, Typography, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AccessContext } from '@4r/module-common-authorization';
import { IdcardOutlined, HistoryOutlined, LogoutOutlined } from '@ant-design/icons';
import { ChevronsLeft, ChevronsRight } from 'react-feather';
import './LeftMenu.scss';
import { displayProgress } from '@4r/module-common-ant-components';
import { useFeatureFlag } from '@4r/module-common-feature-flags-mf-host';
import { I4EnterpriseContracts, I4EnterpriseLeftMenus } from '@4r/mf-contracts';
import { FeatureFlags } from '../../common/consts';
import PermissionType from '../../authorization/PermissionType';
import { AuthContext } from '../../authentication/AuthProvider';
import { ContractsContext } from '../../contexts/ContractsContext';
import { ReactComponent as OrdersSVG } from '../../assets/svg/orders.svg';
import { ReactComponent as AssetsSVG } from '../../assets/svg/assets.svg';
import { ReactComponent as AccessSVG } from '../../assets/svg/access.svg';
import { ReactComponent as ApprovalsSVG } from '../../assets/svg/approvals.svg';
import { ReactComponent as SettingsSVG } from '../../assets/svg/settings.svg';
import { ReactComponent as LogoSVG } from '../../assets/svg/logo_small.svg';
import { ReactComponent as LogoFullSVG } from '../../assets/svg/logo_full.svg';
import { ReactComponent as VendorSVG } from '../../assets/svg/vendor.svg';
import { ReactComponent as LogoutSVG } from '../../assets/svg/logout.svg';
import Login from '../../authentication/Login';
import { UserVendorModel } from '../../api/api';
import OnlineStatus from './OnlineStatus';
import BackgroundDownloading from './BackgroundDownloading';

interface ILeftMenuProps {
	environment: { name: string; color: string; isProduction?: boolean } | null;
	menuCollapsed: boolean;
	onMenuItemClicked: () => void;
	mobile: boolean;
	vendors: UserVendorModel[];
	vendor: UserVendorModel | null | undefined;
	onVendorChange: (id: string) => void;
	onCollapseChange: () => void;
	menuState: MenuState;
}

enum LogoutState {
	LoggedIn,
	Pending,
	LoggedOut,
}

export enum MenuState {
	Collapsed,
	Expanded,
	Pinned,
}

const staticMenuItems: I4EnterpriseContracts = {};

const LeftMenu = ({
	environment,
	menuCollapsed,
	onMenuItemClicked,
	mobile,
	vendors,
	vendor,
	onVendorChange,
	onCollapseChange,
	menuState,
}: ILeftMenuProps): ReactElement => {
	const vendorOrdersEnabled = useFeatureFlag(FeatureFlags.VendorOrders);
	const { isAuthenticated, authService } = useContext(AuthContext);
	const access = useContext(AccessContext);
	const { pathname } = useLocation();
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [logoutState, setLogoutState] = useState<LogoutState>(LogoutState.LoggedIn);
	const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const [showFade, setShowFade] = useState(false);
	const { contracts } = useContext(ContractsContext);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const menuItems = React.useMemo(() => {
		const filterSubMenus = (menus: I4EnterpriseLeftMenus): I4EnterpriseLeftMenus =>
			Object.entries(menus).reduce<I4EnterpriseLeftMenus>(
				(acc, [key, menu]) =>
					menu.enabled !== false &&
					(!menu.allPermissions || menu.allPermissions.every((x) => access.has(x as PermissionType))) &&
					(!menu.anyPermissions || menu.anyPermissions.some((x) => access.has(x as PermissionType)))
						? { ...acc, [key]: { ...menu, subMenus: menu.subMenus ? filterSubMenus(menu.subMenus) : undefined } }
						: acc,
				{},
			);

		return Object.entries(contracts)
			.filter(([, item]) => item.enabled !== false)
			.concat(Object.entries(staticMenuItems))
			.sort(([keyA, valueA], [keyB, valueB]) => valueA.priority - valueB.priority || keyA.localeCompare(keyB))
			.map(([, contract]) => filterSubMenus(contract.leftMenus))
			.flatMap((i) => Object.keys(i).map((key) => i[key]));
	}, [contracts, access]);

	// the main menu items
	const topMenuItems = React.useMemo(() => menuItems.filter((x) => (x.priority ?? 0) >= 0), [menuItems]);
	// the menu items that will show up above the online status icon
	const bottomMenuItems = React.useMemo(() => menuItems.filter((x) => (x.priority ?? 0) < 0), [menuItems]);

	const onTitleClick = (key: string) => {
		if (openKeys.includes(key)) {
			setOpenKeys([]);
		} else {
			setOpenKeys([key]);
		}
	};

	const handleSignOutFlow = () =>
		displayProgress(async () => {
			setLogoutState(LogoutState.Pending);
			setIsLogoutModalVisible(false);
			try {
				await authService?.logout(`${window.location.origin}/logout`);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				setErrorMessage(e.message);
			} finally {
				setLogoutState(LogoutState.LoggedOut);
			}
		});

	const getExpandIcon = () => {
		switch (menuState) {
			case MenuState.Collapsed:
				return <ChevronsRight />;

			case MenuState.Pinned:
				return <ChevronsLeft />;

			default:
				return <ChevronsRight />;
		}
	};

	const calculateScroll = () => {
		if (ref.current?.scrollHeight) {
			if (ref.current?.clientHeight - ref.current?.scrollHeight < 0) {
				setShowFade(true);
			} else {
				setShowFade(false);
			}
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			window.addEventListener('resize', calculateScroll);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		calculateScroll();
	}, [isAuthenticated, access]);

	return (
		<>
			<div ref={ref} className={`left-menu--main ${showFade ? 'left-menu--faded' : ''}`}>
				<Menu
					triggerSubMenuAction="click"
					selectedKeys={pathname.split('/')}
					mode={mobile ? 'inline' : 'vertical'}
					style={{ borderRight: 0 }}
					openKeys={mobile ? openKeys : undefined}
				>
					{!mobile ? (
						<>
							{environment && !environment.isProduction && (
								<Row justify="center" style={{ marginBottom: 12 }}>
									<Tag
										className="left-menu__tag"
										style={{
											background: environment.color,
										}}
									>
										{environment.name.toLocaleUpperCase()}
									</Tag>
								</Row>
							)}
							<Link to="/" onClick={onMenuItemClicked}>
								<Row justify="center" style={{ margin: '12px 0' }}>
									{menuCollapsed ? <LogoSVG /> : <LogoFullSVG id="logo_full" />}
								</Row>
							</Link>

							<Menu.Divider />
						</>
					) : (
						<>
							<Row justify="center">
								<Col xs={24}>
									<Login menuState={menuState} isMobile />
								</Col>
							</Row>
						</>
					)}

					{isAuthenticated && (
						<>
							{vendors.length > 1 && (
								<>
									<Menu.SubMenu
										onTitleClick={() => onTitleClick('vendorProfile')}
										key="vendorProfile"
										title="Switch vendor profile"
										icon={
											<Badge count={vendor?.name.charAt(0)} size="small" offset={[-20, 12]}>
												<IdcardOutlined style={{ fontSize: 22 }} />
											</Badge>
										}
									>
										<Menu.ItemGroup title="Switch vendor profile">
											<Menu.Divider />
											{vendors.map((vendorElem) => (
												<Menu.Item
													style={{ margin: 0, padding: '0 12px' }}
													key={vendorElem.id}
													onClick={() => {
														onVendorChange(vendorElem.id);
														onMenuItemClicked();
														setOpenKeys([]);
													}}
												>
													<Radio checked={vendorElem.id === vendor?.id}>{vendorElem.name}</Radio>
												</Menu.Item>
											))}
										</Menu.ItemGroup>
									</Menu.SubMenu>

									<Menu.Divider />
								</>
							)}

							{access.has(PermissionType.ViewLifecycles) && (
								<Menu.Item
									key="lifecycles"
									icon={<HistoryOutlined style={{ fontSize: 18, marginRight: 6 }} />}
									style={{ margin: '12px 0' }}
									title=""
								>
									<Link to="/lifecycles" onClick={onMenuItemClicked} style={{ margin: '12px 0' }}>
										Lifecycles
									</Link>
								</Menu.Item>
							)}

							{access.has(PermissionType.ViewScheduledWorkOrders) && (
								<Menu.Item key="orders" icon={<OrdersSVG style={{ marginRight: 6 }} />} title="" style={{ margin: '12px 0' }}>
									<Link to="/orders" onClick={onMenuItemClicked}>
										Orders
									</Link>
								</Menu.Item>
							)}

							{access.has(PermissionType.AccessProperties) && (
								<Menu.Item key="access" icon={<AccessSVG style={{ marginRight: 6 }} />} title="" style={{ margin: '12px 0' }}>
									<Link to="/access/properties" onClick={onMenuItemClicked}>
										4Access
									</Link>
								</Menu.Item>
							)}

							{access.has(PermissionType.ViewApprovals) && (
								<Menu.Item key="approvals" icon={<ApprovalsSVG style={{ marginRight: 6 }} />} title="" style={{ margin: '12px 0' }}>
									<Link to="/approvals" onClick={onMenuItemClicked}>
										4Approvals
									</Link>
								</Menu.Item>
							)}

							{(access.has(PermissionType.ViewTerritoryData) ||
								access.has(PermissionType.ViewScopeManagement) ||
								access.has(PermissionType.ViewResourceManagement)) && (
								<Menu.SubMenu
									key="settings"
									title="Settings"
									icon={<SettingsSVG style={{ marginRight: 6 }} />}
									onTitleClick={() => onTitleClick('settings')}
								>
									{access.has(PermissionType.ViewTerritoryData) && (
										<Menu.Item key="territory" title="" style={{ margin: '12px 0' }}>
											<Link
												to="/settings/territory"
												onClick={() => {
													onMenuItemClicked();
													setOpenKeys([]);
												}}
											>
												Territory Management
											</Link>
										</Menu.Item>
									)}
									{access.has(PermissionType.ViewScopeManagement) && (
										<Menu.Item key="scope" title="" style={{ margin: '12px 0' }}>
											<Link
												to="/settings/scope"
												onClick={() => {
													onMenuItemClicked();
													setOpenKeys([]);
												}}
											>
												Scope Management
											</Link>
										</Menu.Item>
									)}
									{access.has(PermissionType.ViewResourceManagement) && (
										<Menu.Item key="resource-management" title="" style={{ margin: '12px 0' }}>
											<Link
												to="/settings/resource"
												onClick={() => {
													onMenuItemClicked();
													setOpenKeys([]);
												}}
											>
												Resource Management
											</Link>
										</Menu.Item>
									)}
								</Menu.SubMenu>
							)}

							{access.has(PermissionType.ViewAssets) && (
								<Menu.Item key="assets" icon={<AssetsSVG style={{ marginRight: 6 }} />} title="" style={{ margin: '12px 0' }}>
									<Link to="/assets" onClick={onMenuItemClicked}>
										Assets
									</Link>
								</Menu.Item>
							)}

							{access.has(PermissionType.ViewAssociatedVendor) && (
								<Menu.Item key="vendor" icon={<VendorSVG style={{ marginRight: 6 }} />} title="" style={{ margin: '12px 0' }}>
									<Link to="/vendor/profile" onClick={onMenuItemClicked}>
										My Vendor Profile
									</Link>
								</Menu.Item>
							)}

							{vendorOrdersEnabled &&
								(access.has(PermissionType.PersonaExternalTechnician) || access.has(PermissionType.PersonaExternalAdmin)) && (
									<Menu.SubMenu
										key="vendor-orders"
										title="Orders"
										icon={<OrdersSVG style={{ marginRight: 6 }} />}
										onTitleClick={() => onTitleClick('vendor-orders')}
									>
										{access.has(PermissionType.PersonaExternalAdmin) && (
											<Menu.Item key="vendor-admin-orders" title="" style={{ margin: '12px 0' }}>
												<Link
													to="/vendor-admin-orders"
													onClick={() => {
														onMenuItemClicked();
														setOpenKeys([]);
													}}
												>
													Vendor Orders
												</Link>
											</Menu.Item>
										)}
										{access.has(PermissionType.PersonaExternalTechnician) && (
											<Menu.Item key="vendor-user-orders" title="" style={{ margin: '12px 0' }}>
												<Link
													to="/vendor-user-orders"
													onClick={() => {
														onMenuItemClicked();
														setOpenKeys([]);
													}}
												>
													Vendor User Orders
												</Link>
											</Menu.Item>
										)}
									</Menu.SubMenu>
								)}

							{topMenuItems.flatMap((item) => (
								<Menu.Item key={item.route ?? ''} title="" icon={item.icon} style={{ margin: '12px 0' }}>
									<Link
										to={item.route ?? ''}
										onClick={() => {
											onMenuItemClicked();
											setOpenKeys([]);
										}}
									>
										{item.title}
									</Link>
								</Menu.Item>
							))}

							{errorMessage && <div>{errorMessage}</div>}
						</>
					)}
				</Menu>
			</div>

			{isAuthenticated && (
				<>
					{mobile ? (
						<>
							<Divider style={{ margin: 0 }} />
							<Menu
								triggerSubMenuAction="click"
								selectedKeys={pathname.split('/')}
								mode="inline"
								style={{ borderRight: 0 }}
								className="left-menu--main"
								openKeys={mobile ? openKeys : undefined}
							>
								<Menu.Item
									onClick={() => setIsLogoutModalVisible(true)}
									disabled={logoutState === LogoutState.Pending}
									icon={<LogoutSVG />}
								>
									Logout
								</Menu.Item>
							</Menu>
						</>
					) : (
						<div className="left-menu__column__footer">
							<Menu
								triggerSubMenuAction="click"
								selectedKeys={pathname.split('/')}
								mode="inline"
								style={{ borderRight: 0 }}
								className="left-menu--main"
								openKeys={mobile ? openKeys : undefined}
							>
								{bottomMenuItems.flatMap((item) => (
									<Menu.Item key={item.route} icon={item.icon}>
										<Link to={item.route ?? '/'} onClick={onMenuItemClicked}>
											{item.title}
										</Link>
									</Menu.Item>
								))}
							</Menu>

							<BackgroundDownloading />

							<Link key="status" to="/status">
								<OnlineStatus />
							</Link>

							<Row key="user" justify="center" style={{ marginBottom: 12 }}>
								<Divider style={{ marginTop: 6, marginBottom: 6 }} />
								<Button type="link" onClick={onCollapseChange} className="left-menu__arrow">
									{getExpandIcon()}
								</Button>
								<Divider style={{ marginTop: 6, marginBottom: 12 }} />

								<Menu style={{ borderRight: 0, width: '100%' }} triggerSubMenuAction="click">
									<Menu.SubMenu
										className="left-menu__login"
										key="login"
										style={{ padding: 0 }}
										icon={<Login menuState={menuState} isMobile={false} />}
									>
										<Menu.Item key="logout" onClick={() => setIsLogoutModalVisible(true)} icon={<LogoutOutlined />}>
											Logout
										</Menu.Item>
									</Menu.SubMenu>
								</Menu>
							</Row>
						</div>
					)}
				</>
			)}

			{!isAuthenticated && !mobile && (
				<div className="left-menu__column__footer">
					<Row justify="center">
						<Divider style={{ marginBottom: 6 }} />
						<Button type="link" onClick={onCollapseChange} className="left-menu__arrow">
							{getExpandIcon()}
						</Button>
						<Divider style={{ marginTop: 6, marginBottom: 12 }} />
					</Row>
					<Row justify="center" style={{ marginBottom: 12 }}>
						<Login menuState={menuState} isMobile={false} />
					</Row>
				</div>
			)}

			<Modal
				className="fullscreen-modal"
				title="Logout"
				okText="Logout"
				visible={isLogoutModalVisible}
				onOk={handleSignOutFlow}
				onCancel={() => setIsLogoutModalVisible(false)}
				cancelButtonProps={{ type: 'dashed' }}
			>
				<Row style={{ margin: '24px 0' }}>
					<Col>
						<Row justify="center" style={{ marginBottom: 24 }}>
							<LogoutSVG />
						</Row>
						<Row justify="center">
							<Typography.Title level={4}>Are you sure you want to log out?</Typography.Title>
							<p>You are about to log out. Anything that hasn&apos;t been saved will be lost.</p>
						</Row>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default LeftMenu;
