import { IDBPDatabase } from 'idb';
import { IConditionIssueService } from '@4r/mf-contracts-4services';
import { CommandType, CommandTitle } from '../CommandTypes';
import BaseOfflineCapableService from '../Offline/BaseOfflineCapableService';
import {
	CreateConditionIssueInstanceCommand,
	CreateConditionIssueInstanceRequest,
	CreateRemedyInstanceCommand,
	CreateRemedyInstanceRequest,
	DeleteConditionIssueInstanceCommand,
	DeleteRemedyInstanceCommand,
	EscalateConditionIssueInstanceCommand,
	EscalateConditionIssueInstanceRequest,
	EscalationType2,
	UpdateConditionIssueInstanceStatusCommand,
	UpdateConditionIssueInstanceStatusRequest,
} from '../../api/api';
import StoreName from '../StoreName';
import { TaxonomyItemModel } from '../../api/apiAssets';
import { ASSET_TYPES_KEY } from '../../common/consts';
import { ICommand } from '../Offline/ICommand';
import { ICommandHandlerCollection } from '../Offline/ICommandHandlerCollection';
import { IOrderCommandService } from '../OrderCommandService/IOrderCommandService';


export default class OfflineConditionIssueServiceDecorator extends BaseOfflineCapableService implements IConditionIssueService {
	constructor(private target: IConditionIssueService, private commandService: IOrderCommandService, db: IDBPDatabase, handlers: ICommandHandlerCollection) {
		super(db);

		handlers.add(CommandType.ConditionIssueCreate, (command: ICommand<CreateConditionIssueInstanceCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.ConditionIssueDelete, (command: ICommand<DeleteConditionIssueInstanceCommand>) => this.commandService.submitCommand(command));

		handlers.add(CommandType.ConditionIssueEscalate, (command: ICommand<EscalateConditionIssueInstanceCommand>) =>
			this.commandService.submitCommand(command)
		);

		handlers.add(CommandType.ConditionIssueUpdateStatus, (command: ICommand<UpdateConditionIssueInstanceStatusCommand>) =>
			this.commandService.submitCommand(command)
		);

		handlers.add(CommandType.RemedyCreate, (command: ICommand<CreateRemedyInstanceCommand>) =>
			this.commandService.submitCommand(command)
		);

		handlers.add(CommandType.RemedyDelete, (command: ICommand<DeleteRemedyInstanceCommand>) => this.commandService.submitCommand(command));
	}

	addConditionIssue(request: CreateConditionIssueInstanceRequest): Promise<void> {
		return this.addCommand<CreateConditionIssueInstanceCommand>({
			payload: request,
			commandType: CommandType.ConditionIssueCreate,
			commandTitle: CommandTitle.ConditionIssueCreate,
		}) as any;
	}

	deleteConditionIssue(id: string): Promise<void> {
		return this.addCommand<DeleteConditionIssueInstanceCommand>({
			payload: { id },
			commandType: CommandType.ConditionIssueDelete,
			commandTitle: CommandTitle.ConditionIssueDelete,
		}) as any;
	}

	escalateConditionIssueInstance(id: string, request: EscalateConditionIssueInstanceRequest): Promise<void> {
		return this.addCommand<EscalateConditionIssueInstanceCommand>({
			payload: {
				id,
				...request,
				type: EscalationType2[request.type as unknown as keyof typeof EscalationType2]
			},
			commandType: CommandType.ConditionIssueEscalate,
			commandTitle: CommandTitle.ConditionIssueEscalate,
		}) as any;
	}

	updateConditionIssueInstanceStatus(id: string, request: UpdateConditionIssueInstanceStatusRequest, statusName: string): Promise<void> {
		return this.addCommand<UpdateConditionIssueInstanceStatusCommand & { statusName: string }>({
			payload: {
				id,
				...request,
				statusName
			},
			commandType: CommandType.ConditionIssueUpdateStatus,
			commandTitle: CommandTitle.ConditionIssueUpdateStatus,
		}) as any;
	}

	addRemedy(request: CreateRemedyInstanceRequest): Promise<void> {
		return this.addCommand<CreateRemedyInstanceCommand>({
			payload: request,
			commandType: CommandType.RemedyCreate,
			commandTitle: CommandTitle.RemedyCreate,
		}) as any;
	}

	deleteRemedy(id: string, orderId: string): Promise<void> {
		return this.addCommand<DeleteRemedyInstanceCommand>({
			payload: { id, orderId },
			commandType: CommandType.RemedyDelete,
			commandTitle: CommandTitle.RemedyDelete,
		}) as any;
	}

	async getAssetReferences(): Promise<TaxonomyItemModel[]> {
		try {
			const assetTypes = await this.target.getAssetReferences();
			await this.db?.put(StoreName.AssetTypes, assetTypes, ASSET_TYPES_KEY);
			return assetTypes;
		} catch (error) {
			const assetTypes = await this.db?.get(StoreName.AssetTypes, ASSET_TYPES_KEY);
			return assetTypes;
		}
	}
}
