import { IDBPDatabase, IDBPTransaction, openDB } from 'idb';
import { addService, getService } from '@4r/mf-host';
import { ServiceNames as ServiceNames4S } from '@4r/mf-contracts-4services';
import { IAuthService } from '../authentication/AuthService';
import ApiOrderService from './OrderService/ApiOrderService';
import OfflineOrderServiceDecorator from './OrderService/OfflineOrderServiceDecorator';
import ApiAssetService from './AssetService/ApiAssetService';
import StoreName from './StoreName';
import IndexName from './IndexName';
import CommandQueueService from './Offline/CommandQueueService';
import { Settings } from '../contexts/types';
import CommandHandlerCollection from './Offline/CommandHandlerCollection';
import ApiFileService from './FileService/ApiFileService';
import OfflineFileServiceDecorator from './FileService/OfflineFileServiceDecorator';
import ServiceNames from './Services';
import ApiServiceTaskService from './ServiceTaskService/ApiServiceTaskService';
import OfflineServiceTaskServiceDecorator from './ServiceTaskService/OfflineServiceTaskServiceDecorator';
import ApiConditionIssueService from './ConditionIssueService/ApiConditionIssueService';
import OfflineConditionIssueServiceDecorator from './ConditionIssueService/OfflineConditionIssueServiceDecorator';
import OfflineAssetServiceDecorator from './AssetService/OfflineAssetServiceDecorator';
import StorageServiceAdapter from './Offline/Adapters/StorageServiceAdapter';
import { COMMAND_HANDLER_REGISTERED_EVENT, CommandHandlerRegisteredEvent } from './Offline/Adapters/CommandHandlerRegisteredEvent';
import CommandHandlerRegistryServiceAdapter from './Offline/Adapters/CommandHandlerRegistryServiceAdapter';
import OfflineCapableDiagnosticsServiceDecorator from './LogService/OfflineLogServiceDecorator';
import ApiDiagnosticsService from './LogService/ApiLogService';
import LoggerService from './LoggerService';
import ApiCommandService from './OrderCommandService/ApiOrderCommandService';
import CurrentOrderIdService from './CurrentOrderIdService';
import CommmandServiceAdapter from './Offline/Adapters/CommandServiceAdapter';

export default class ServiceLocator {
	private logger: LoggerService;

	public commandsQueueService?: CommandQueueService;

	public fileCommandsQueueService?: CommandQueueService;

	private readonly offlineMicrofrontendNames: string[];

	static upgradeDatabase(
		db: IDBPDatabase<unknown>,
		oldVersion: number,
		newVersion: number | null,
		transaction: IDBPTransaction<unknown, string[], 'versionchange'>,
	) {
		if (!db.objectStoreNames.contains(StoreName.Orders)) {
			db.createObjectStore(StoreName.Orders, {
				keyPath: 'order.id',
			});
		}
		if (!db.objectStoreNames.contains(StoreName.Commands)) {
			db.createObjectStore(StoreName.Commands);
		}
		if (!db.objectStoreNames.contains(StoreName.FileCommands)) {
			db.createObjectStore(StoreName.FileCommands);
		}
		if (!db.objectStoreNames.contains(StoreName.Data)) {
			db.createObjectStore(StoreName.Data);
		}
		if (!db.objectStoreNames.contains(StoreName.OrderInventory)) {
			db.createObjectStore(StoreName.OrderInventory, {
				keyPath: 'orderId',
			});
		}
		if (!db.objectStoreNames.contains(StoreName.UserInventory)) {
			db.createObjectStore(StoreName.UserInventory);
		}
		if (!db.objectStoreNames.contains(StoreName.User)) {
			db.createObjectStore(StoreName.User);
		}
		if (!db.objectStoreNames.contains(StoreName.Common)) {
			db.createObjectStore(StoreName.Common);
		}
		if (!db.objectStoreNames.contains(StoreName.Assets)) {
			db.createObjectStore(StoreName.Assets, {
				keyPath: 'id',
			});
		}
		if (!db.objectStoreNames.contains(StoreName.PropertyInfo)) {
			db.createObjectStore(StoreName.PropertyInfo);
		}
		if (!db.objectStoreNames.contains(StoreName.AssetTypesAllAttributes)) {
			db.createObjectStore(StoreName.AssetTypesAllAttributes);
		}
		if (!db.objectStoreNames.contains(StoreName.AssetTypes)) {
			db.createObjectStore(StoreName.AssetTypes);
		}

		const store = transaction.objectStore(StoreName.Commands);
		if (!store.indexNames.contains(IndexName.PropertyId)) {
			store.createIndex(IndexName.PropertyId, 'propertyId', { unique: false });
		}
		if (!store.indexNames.contains(IndexName.OrderId)) {
			store.createIndex(IndexName.OrderId, 'orderId', { unique: false });
		}
		if (!store.indexNames.contains(IndexName.UserId)) {
			store.createIndex(IndexName.UserId, 'userId', { unique: false });
		}
		const fileStore = transaction.objectStore(StoreName.FileCommands);
		if (!fileStore.indexNames.contains(IndexName.FileId)) {
			fileStore.createIndex(IndexName.FileId, 'fileId', { unique: false });
		}
		if (!fileStore.indexNames.contains(IndexName.OrderId)) {
			fileStore.createIndex(IndexName.OrderId, 'orderId', { unique: false });
		}
	}

	constructor(private authService: IAuthService, private settings: Settings) {
		// Collect all the offline capable MF names
		this.logger = new LoggerService('ServiceLocator');
		this.offlineMicrofrontendNames = settings.APPS.filter((x) => x.OFFLINE_SUPPORT === 'true' && x.ENABLED === 'true').map((x) => x.NAME);
	}

	async init(): Promise<void | IDBPDatabase<unknown>> {
		const db = await openDB('offline_db_v2', 21, {
			upgrade: ServiceLocator.upgradeDatabase,
		});

		const currentOrderIdService = new CurrentOrderIdService();
		addService(ServiceNames.CurrentOrderIdService, currentOrderIdService);

		const commandHandlerCollection = new CommandHandlerCollection();

		// Register all known services
		const apiCommandService = new ApiCommandService(this.authService, this.settings);
		const apiFileService = new ApiFileService(this.authService, this.settings);
		const apiOrderService = new ApiOrderService(this.authService, this.settings);
		const apiServiceTaskService = new ApiServiceTaskService(this.authService, this.settings);
		const apiConditionIssueService = new ApiConditionIssueService(this.authService, this.settings);
		const apiAssetService = new ApiAssetService(this.authService, this.settings);
		const apiDiagnosticsService = new ApiDiagnosticsService(this.authService, this.settings);
		const offlineFileService = new OfflineFileServiceDecorator(apiFileService, apiCommandService, db, commandHandlerCollection);
		const offlineOrderService = new OfflineOrderServiceDecorator(apiOrderService, apiCommandService, db, commandHandlerCollection);
		const offlineServiceTaskService = new OfflineServiceTaskServiceDecorator(apiServiceTaskService, apiCommandService, db, commandHandlerCollection);
		const offlineConditionIssueService = new OfflineConditionIssueServiceDecorator(apiConditionIssueService, apiCommandService, db, commandHandlerCollection);
		const offlineAssetService = new OfflineAssetServiceDecorator(apiAssetService, apiCommandService, currentOrderIdService, db, commandHandlerCollection);
		const offlineDiagnosticsService = new OfflineCapableDiagnosticsServiceDecorator(apiDiagnosticsService, apiCommandService, db, commandHandlerCollection);

		this.fileCommandsQueueService = new CommandQueueService(db, StoreName.FileCommands, commandHandlerCollection);
		this.commandsQueueService = new CommandQueueService(db, StoreName.Commands, commandHandlerCollection);

		// Refactor at some point: https://ah4r.atlassian.net/browse/FSBAU-443
		addService(ServiceNames.File, offlineFileService);
		addService(ServiceNames.Order, offlineOrderService);
		addService(ServiceNames.ServiceTask, offlineServiceTaskService);
		addService(ServiceNames.ConditionIssue, offlineConditionIssueService);
		addService(ServiceNames.Asset, offlineAssetService);
		addService(ServiceNames.Log, offlineDiagnosticsService);



		const commandHandlerRegistryService = new CommandHandlerRegistryServiceAdapter(commandHandlerCollection);
		addService(ServiceNames4S.CommandRegistry, commandHandlerRegistryService);

		const commandService = new CommmandServiceAdapter(commandHandlerCollection, db);
		addService(ServiceNames4S.Command, commandService);

		const storageService = new StorageServiceAdapter(db);
		addService(ServiceNames4S.Storage, storageService);

		this.ensureQueuesStarted();

		return db;
	}

	private ensureQueuesStarted() {
		const waitOnMfs = [...this.offlineMicrofrontendNames];
		let queuesStarted = false;

		const tryStartCommandQueues = () => {
			if (waitOnMfs.length === 0 && !queuesStarted) {
				this.logger.logInfo('Starting command queues');
				this.commandsQueueService?.start();
				this.fileCommandsQueueService?.start();
				queuesStarted = true;
			}
		};

		// Start sync only when all the offline capable MFs initialize
		getService('MessageBusService').on(COMMAND_HANDLER_REGISTERED_EVENT, (e: CustomEvent<CommandHandlerRegisteredEvent>) => {
			this.logger.logInfo(`Microfrontend loaded ${e.detail.name}`);

			const i = waitOnMfs.indexOf(e.detail.name);
			if (i !== -1) {
				waitOnMfs.splice(i, 1);
			}
			tryStartCommandQueues();
		});
		tryStartCommandQueues();
	}
}
